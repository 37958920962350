import React from "react"
import Helmet from "react-helmet"

const SEO = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="BCRD" />
      <meta http-equiv="Cache-control" content="no-cache" />
      <meta name="keywords" content="Rollerderby, Ludwigsburg" />
      <meta name="image" content="/logo.png" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://rollerderby-ludwigsburg.de" />
      <meta property="og:img" content="logo.png" />
      <meta property="og:description" content="BCRD" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="BCRD" />
    </Helmet>
  )
}

export default SEO
